<template>
  <v-container
    id="widgets"
    fluid
    tag="section"
    class="pt-0"
  >
    <!-- INFO -->
    <base-material-card
      color="warning"
      inline
      class="col-12"
      filter
    >
      <template #heading>
        <div class="text-h3 font-weight-light">
          <v-icon>mdi-calendar-month</v-icon> Договоры
        </div>
      </template>
      <template #after-heading>
        <v-switch
          v-model="showFilter"
          color="success"
          label="Фильтр"
          class="mr-3 pt-0"
          dense
        />
        <v-btn
          color="info"
          default
          rounded
          class="mt-2"
          @click.stop="openAdd"
        >
          <v-icon class="mr-2">
            mdi-calendar-plus
          </v-icon>
          Добавить
        </v-btn>
      </template>
      <v-card-text>
        <v-row
          v-if="showFilter"
          class="d-sm-flex"
        >
          <v-col sm="2">
            <v-select
              v-model="ContractsStatusSelect"
              :items="ContractsStatus"
              item-text="text"
              item-value="val"
              label="Договоры:"
              @change="updateList"
            />
          </v-col>
          <v-col
            sm="4"
            class="d-flex"
          >
            <v-select
              v-model="ContractDayDate"
              :items="days"
              prepend-icon="mdi-calendar"
              class="pr-2"
              label="Дата заключения:"
              hint="день"
              persistent-hint
              clearable
            />
            <v-select
              v-model="ContractMonthDate"
              :items="monthNames"
              clearable
              hint="месяц"
              persistent-hint
              class="px-2"
            />
            <v-select
              v-model="ContractYearDate"
              :items="years"
              class="pl-2"
              hint="год"
              persistent-hint
              clearable
            />
          </v-col>
          <v-col
            sm="4"
            class="d-flex"
          >
            <v-select
              v-model="ExpireDayDate"
              :items="days"
              prepend-icon="mdi-calendar"
              class="pr-2"
              label="Дата окончания:"
              hint="день"
              persistent-hint
              clearable
            />
            <v-select
              v-model="ExpireMonthDate"
              :items="monthNames"
              clearable
              hint="месяц"
              persistent-hint
              class="px-2"
            />
            <v-select
              v-model="ExpireYearDate"
              :items="years"
              class="pl-2"
              hint="год"
              persistent-hint
              clearable
            />
          </v-col>
          <v-col
            sm="2"
            class="d-flex"
          >
            <v-checkbox
              v-model="checkbox"
              label="бессрочный"
            />
          </v-col>
          <!--v-col sm="6">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="ExpireDateF"
                label="Дата окончания"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="ExpireDate"
              @input="menu2 = false"
              first-day-of-week = 1
              locale="ru"
            ></v-date-picker>
          </v-menu>
          </v-col-->
          <v-col sm="2">
            <v-text-field
              v-model="ContractN"
              label="Номер договора:"
              clearable
            />
          </v-col>
          <v-col sm="2">
            <v-select
              v-model="ContractsTypesListSelect"
              :items="ContractsTypesList"
              item-text="title"
              item-value="contracttypeid"
              label="Тип договора:"
              clearable
            />
          </v-col>
          <v-col sm="4">
            <v-autocomplete
              v-model="ContractsCagentsSelect"
              :items="ContractsCagents"
              item-text="titleinn"
              item-value="cagentid"
              label="Заказчик"
              clearable
            />
          </v-col>
          <v-col sm="4">
            <v-autocomplete
              v-model="ContractsVendorsSelect"
              :items="ContractsCagents"
              item-text="titleinn"
              item-value="cagentid"
              label="Исполнитель"
              clearable
            />
          </v-col>
        </v-row>
        <v-data-table
          :headers="ConHeaders"
          :items="Contracts"
          class="elevation-3 col-12 pa-2 mt-4"
          :loading="tableLoading"
          no-data-text="нет данных"
          :search="ContractN"
          :footer-props="{itemsPerPageText:'Строки на странице:'}"
        >
          <template #item="{ item }">
            <tr
              style="cursor: pointer"
              @click="contractCardShow(item)"
            >
              <td class="w-15">
                {{ item?item.contractnumber:'' }}
              </td>
              <td class="w-10">
                {{ item.contractdates }}
              </td>
              <td class="w-10">
                {{ item.expiredates }}
              </td>
              <td class="w-15">
                {{ cTypeStr(item.contracttypeid) }}
              </td>
              <td class="w-25">
                {{ item.titlecustomer }}
              </td>
              <td class="w-25">
                {{ item.titlevendor }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
    <v-dialog
      v-model="addpopup"
      scrollable
      max-width="1200px"
    >
      <v-card class="contr-card">
        <v-card-title class="blue-grey lighten-5">
          Договор № {{ itemSelect.contractnumber }}
        </v-card-title>
        <v-card-text
          @drop.prevent="addDropFile"
          @dragover.prevent="dragover"
        >
          <v-container>
            <v-divider />
            <v-row>
              <v-col>
                Дата заключения: <b>{{ itemSelect.contractdates }}</b>
              </v-col>
              <v-col
                v-if="itemSelect.expiredate"
                class="text-right"
              >
                Дата окончания: <b>{{ itemSelect.expiredates }}</b>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex">
                <div>
                  Тип договора: <b>{{ cTypeStr(itemSelect.contracttypeid) }}</b>
                </div>
                <div class="ml-md-4">
                  Срок оплаты (дней): <b>{{ itemSelect.payperiod }}</b>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p>
                  {{ itemSelect.titlevendortype }}: <b>{{ itemSelect.titlevendor }}</b>
                </p>
                <p v-show="itemSelect.vendoragentname">
                  В лице: <b>{{ itemSelect.vendoragentname }}</b>
                </p>
              </v-col>
              <v-col class="text-md-right">
                <p>
                  {{ itemSelect.titlecustomertype }}: <b>{{ itemSelect.titlecustomer }}</b>
                </p>
                <p v-show="itemSelect.customeragentname">
                  В лице: <b>{{ itemSelect.customeragentname }}</b>
                </p>
              </v-col>
            </v-row>
            <v-divider />
            <v-progress-linear
              v-if="!itemSelectfiles && fileLoading"
              color="deep-purple lighten-4"
              indeterminate
              rounded
              height="6"
            />
            <v-list v-else>
              <v-list-item
                v-for="(item,id) in itemSelectfiles"
                :key="item.title"
                class="blue-grey lighten-4"
                @click="showFile({ id:id, file:item, ContractID: itemSelect.contractid })"
              >
                <v-list-item-avatar>
                  <v-icon
                    class="elevation-1 white"
                    :color="fileicon(item.fileextension).color"
                    v-text="fileicon(item.fileextension).icon"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="item.filename + needDot(item.fileextension) + item.fileextension" />
                </v-list-item-content>
                <v-list-item-action />
              </v-list-item>
            </v-list>
            <v-divider />
            <v-row>
              <v-col class="d-flex">
                <v-spacer />
                <span
                  v-cloak
                  class="col-8"
                >
                  <v-file-input
                    v-model="files"
                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/pdf"
                    color="deep-purple accent-4"
                    counter
                    label="Прикрепить файл"
                    multiple
                    placeholder="Нажмите, или перетащите файл в это окно"
                    prepend-icon="mdi-paperclip"
                    :display-size="1000"
                  ><template #selection="{ index, text }">
                    <v-chip
                      v-if="index < 2"
                      color="deep-purple accent-4"
                      dark
                      label
                      small
                    >
                      {{ text }}
                    </v-chip>
                    <span
                      v-else-if="index === 2"
                      class="text-overline grey--text text--darken-3 mx-2"
                    >+{{ files.length - 2 }} File(s)
                    </span>
                  </template>
                  </v-file-input>
                </span>
                <v-btn
                  :loading="fileUploads"
                  color="blue darken-1"
                  text
                  class="mt-5"
                  @click="uploadDoc"
                >
                  <v-icon>mdi-upload</v-icon>Отправить на сервер
                </v-btn>
                <v-spacer />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="blue-grey lighten-5">
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="imgpopup"
      scrollable
      max-width="1100px"
      :fullscreen="fullscreen?true:false"
    >
      <v-card class="contr-card">
        <v-card-title>
          <span class="d-flex">
            <v-btn
              text
              icon
              color="indigo"
              :disabled="imgID==0"
              @click="changeView(imgID-1)"
            >
              <v-icon
                color="indigo"
                large
                style="top: 0px;right: 0px; position: relative"
              >
                mdi-arrow-left-circle-outline
              </v-icon>
            </v-btn>
            <span
              v-if="pdf||image"
              class="mx-auto mt-2"
            >
              {{ imgSelect.FileName }}{{ needDot(imgSelect.FileExtension) }}{{ imgSelect.FileExtension }}
            </span>
            <span
              v-else
              class="mx-auto mt-2"
            >
              Сохранить
            </span>
            <v-btn
              text
              icon
              color="indigo"
              :disabled="maxID"
              @click="changeView(imgID+1)"
            >
              <v-icon
                color="indigo"
                large
                style="top: 0px;right: 0px; position: relative"
              >
                mdi-arrow-right-circle-outline
              </v-icon>
            </v-btn>
          </span>
        </v-card-title>
        <v-card-text
          class="pa-0"
          style="background-color:#d9c7c7;"
        >
          <v-img
            v-if="image"
            ref="image"
            :src="image"
            aspect-ratio="1.7"
            contain
          />
          <pdf
            v-if="pdf"
            ref="pdf"
            :scale.sync="zoom"
            style="border: 1px solid darkgrey; width:100%"
            :src="pdf"
            :page="pdfConfig.page"
            :rotate="pdfConfig.rotate"
            @progress="pdfConfig.loadedRatio = $event"
            @num-pages="pdfConfig.numPages = $event"
            @link-clicked="pdfConfig.page = $event"
          />
          <v-container v-if="!image&&!pdf">
            Файл: {{ imgSelect.FileName }}{{ needDot(imgSelect.FileExtension) }}{{ imgSelect.FileExtension }} Размер: {{ imgSelect.fileSize }}
          </v-container>
        </v-card-text>
        <v-card-actions v-if="image||pdf">
          <v-btn
            text
            @click="fullscreen=fullscreen==true?false:true"
          >
            <v-icon v-if="fullscreen">
              mdi-arrow-collapse
            </v-icon><v-icon v-else>
              mdi-arrow-expand
            </v-icon>
          </v-btn>
          <v-btn
            v-if="pdf"
            text
            @click="$refs.pdf.print()"
          >
            <v-icon>mdi-printer</v-icon>печать
          </v-btn>
          <v-btn
            v-if="image"
            text
            @click="$refs.image.print()"
          >
            <v-icon>mdi-printer</v-icon>печать
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="download"
          >
            <v-icon>mdi-download</v-icon>Сохранить
          </v-btn>
          <span
            v-if="!image"
            class="d-flex"
          >
            <v-btn
              text
              :disabled="pdfConfig.page==1"
              @click="pdfConfig.page--"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <div class="py-3 px-5">{{ pdfConfig.page }}/{{ pdfConfig.numPages }}</div>
            <v-btn
              text
              :disabled="pdfConfig.page==pdfConfig.numPages"
              @click="pdfConfig.page++"
            ><v-icon>mdi-arrow-right</v-icon></v-btn>
          </span>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="imgpopup=!imgpopup"
          >
            закрыть
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="download"
          >
            <v-icon>mdi-download</v-icon>Сохранить
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="imgpopup=!imgpopup"
          >
            закрыть
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showAddContract"
      scrollable
      max-width="800px"
      :fullscreen="fullscreen?true:false"
    >
      <v-card>
        <v-card-title>
          Добавление нового договора
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-row>
              <v-col sm="6">
                <v-text-field
                  v-model="newContractInfo.contractnumber"
                  label="Номер договора:"
                  :rules="nameRulesRequire"
                  required
                  clearable
                />
              </v-col>
              <v-col sm="6">
                <v-select
                  v-model="newContractInfo.contracttypeid"
                  :items="ContractsTypesList"
                  item-text="title"
                  item-value="contracttypeid"
                  label="Тип договора:"
                  :rules="nameRulesRequire"
                  required
                  clearable
                />
              </v-col>
              <v-col sm="5">
                <v-text-field
                  v-model="newContractInfo.contractdate"
                  label="Дата начала"
                  prepend-icon="mdi-calendar"
                  :rules="nameRulesRequire"
                  type="date"
                  required
                  clearable
                />
              </v-col>
              <v-col
                v-if="!timelesscheckbox"
                sm="5"
              >
                <v-text-field
                  v-model="newContractInfo.expiredate"
                  label="Дата окончания"
                  prepend-icon="mdi-calendar"
                  :rules="nameRulesRequire"
                  type="date"
                  required
                  clearable
                />
              </v-col>
              <v-col
                sm="2"
                class="d-flex"
              >
                <v-checkbox
                  v-model="timelesscheckbox"
                  label="бессрочный"
                />
              </v-col>
              <v-col
                sm="6"
                class="d-flex"
              >
                <v-btn
                  class="mt-4"
                  icon
                  @click="addVendor(true)"
                >
                  <v-icon>
                    mdi-account-plus-outline
                  </v-icon>
                </v-btn>
                <v-autocomplete
                  v-model="newContractInfo.customercagentid"
                  :items="ContractsCagents"
                  :rules="nameRulesRequire"
                  item-text="titleinn"
                  item-value="cagentid"
                  label="Заказчик"
                  required
                  clearable
                />
              </v-col>
              <v-col
                sm="6"
                class="d-flex"
              >
                <v-btn
                  class="mt-4"
                  icon
                  @click="addVendor(true)"
                >
                  <v-icon>
                    mdi-account-plus-outline
                  </v-icon>
                </v-btn>
                <v-autocomplete
                  v-model="newContractInfo.vandorcagentid"
                  :items="ContractsCagents"
                  :rules="nameRulesRequire"
                  item-text="titleinn"
                  item-value="cagentid"
                  label="Исполнитель"
                  required
                  clearable
                />
              </v-col>
              <v-col sm="6">
                <v-text-field
                  v-model="newContractInfo.customeragentname"
                  label="Заказчик в лице:"
                  :rules="nameRulesRequire"
                  required
                  clearable
                />
              </v-col>
              <v-col sm="6">
                <v-text-field
                  v-model="newContractInfo.vendoragentname"
                  label="Исполнитель в лице:"
                  :rules="nameRulesRequire"
                  required
                  clearable
                />
              </v-col>
              <v-col
                sm="12"
                class="d-flex"
              >
                <v-spacer />
                <v-checkbox
                  v-model="newContractIsActive"
                  label="Активный договор"
                />
                <v-spacer />
              </v-col>
              <span
                v-cloak
                class="ml-auto mr-auto col-8"
              >
                <v-file-input
                  v-model="filesNew"
                  accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/pdf"
                  color="deep-purple accent-4"
                  counter
                  label="Прикрепить файл"
                  multiple
                  placeholder="Нажмите, или перетащите файл в это окно"
                  prepend-icon="mdi-paperclip"
                  :display-size="1000"
                ><template #selection="{ index, text }">
                  <v-chip
                    v-if="index < 2"
                    color="deep-purple accent-4"
                    dark
                    label
                    small
                  >
                    {{ text }}
                  </v-chip>
                  <span
                    v-else-if="index === 2"
                    class="text-overline grey--text text--darken-3 mx-2"
                  >+{{ files.length - 2 }} File(s)
                  </span>
                </template>
                </v-file-input>
              </span>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="cancelAddContract"
          >
            отмена
          </v-btn>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            :loading="contractCreated"
            @click="addContract"
          >
            добавить
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <add-cagent @cagentadd="cagentadd" />
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import pdf from 'vue-pdf'
  import addCagent from './modals/addCagent'

  export default {
    name: 'Contracts',
    components: {
      pdf, addCagent,
    },
    data () {
      return {
        fileLoading: true,
        vendorAdd: false,
        isEditing: false,
        timelesscheckbox: false,
        nameRulesRequire: [
          v => !!v || 'Необходимо заполнить',
        ],
        valid: true,
        newContractInfo: {
          contractid: '',
          contracttypeid: '',
          vandorcagentid: '',
          customercagentid: '',
          contractnumber: '',
          contractdate: '',
          expiredate: '',
          isactive: 1,
          vendoragentname: '',
          customeragentname: '',
          vendoragentcause: '',
          customeragentcause: '',
          payperiod: '',
        },
        showAddContract: false,
        showFilter: false,
        fullscreen: false,
        checkbox: false,
        ExpireDayDate: null,
        ExpireYearDate: null,
        ExpireMonthDate: null,
        ContractYearDate: null,
        ContractMonthDate: null,
        ContractDayDate: null,
        days: [
          '01', '02', '03', '04', '05',
          '06', '07', '08', '09', '10',
          '11', '12', '13', '14', '15',
          '16', '17', '18', '19', '20',
          '21', '22', '23', '24', '25',
          '26', '27', '28', '29', '30', '31',
        ],
        monthNames: [
          { text: 'Январь', value: '01' }, { text: 'Февраль', value: '02' }, { text: 'Март', value: '03' },
          { text: 'Апрель', value: '04' }, { text: 'Май', value: '05' }, { text: 'Июнь', value: '06' },
          { text: 'Июль', value: '07' }, { text: 'Август', value: '08' }, { text: 'Сентябрь', value: '09' },
          { text: 'Октябрь', value: '10' }, { text: 'Ноябрь', value: '11' }, { text: 'Декабрь', value: '12' },
        ],
        years: ['2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'],
        itemSelectfiles: [],
        zoom: 1,
        pdfConfig: {
          page: 1,
          rotate: 0,
          loadedRatio: 2,
          numPages: 2,
        },
        pdf: null,
        fileURL: null,
        imgSelect: {},
        imgID: null,
        image: null,
        imgpopup: false,
        fileUploads: false,
        files: [],
        filesNew: [],
        itemSelect: {},
        addpopup: false,
        ContractN: null,
        searchStart: false,
        ContractDate: null,
        ContractDateF: null,
        ExpireDate: null,
        ExpireDateF: null,
        menu1: null,
        menu2: null,
        selectedCon: null,
        tableLoading: true,
        loading: false,
        ContractsTypesListSelect: null,
        Contracts: [],
        ContractsCagents: [],
        ContractsCagentsSelect: null,
        ContractsVendorsSelect: null,
        ContractsAreas: [],
        ContractsTypesList: [],
        ContractsStatusSelect: 1,
        ContractsStatus: [
          { val: 1, text: 'Только активные' },
          { val: 0, text: 'Только неактивные' },
          { val: -1, text: 'Все' },
        ],
        FileExtensionImages: ['jpg', 'png', 'bmp', 'jpeg'],
        contractCreated: false,
      }
    },
    computed: {
      ...mapGetters({
      }),
      maxID () {
        if (!this.itemSelectfiles) return false
        return this.imgID == this.itemSelectfiles.length - 1
      },
      showAddCagent: {
        get () {
          return this.$store.getters.showAddCagent
        },
        set (val) {
          this.$store.commit('showAddCagent', val)
        },
      },
      newContractIsActive: {
        get () {
          return this.newContractInfo.isactive === 1
        },
        set (val) {
          this.newContractInfo.isactive = Number(val)
        },
      },
      getWinWidth () {
        return window.innerWidth + 'px'
      },
      formattedZoom () {
        return Number.parseInt(this.zoom * 100)
      },
      filterClear () {
        return this.ContractsTypesListSelect != null || this.ExpireDateF != null || this.ContractDateF != null || this.ContractsVendorsSelect != null || this.ContractsCagentsSelect != null || this.ContractsStatusSelect > 0
      },
      ConHeaders () {
        return [
          {
            text: 'Номер договора',
            value: 'contractnumber',
            sortable: false,
          },
          {
            text: 'Дата заключения',
            value: 'contractdates',
            sortable: false,
            filter: value => {
              if (this.ContractYearDate == null && this.ContractMonthDate == null && this.ContractDayDate == null) return true
              if (!value) return false
              const valSplit = value.split('.')
              return ((valSplit[0] === this.ContractDayDate) || !this.ContractDayDate) && ((valSplit[1] === this.ContractMonthDate) || !this.ContractMonthDate) && ((valSplit[2] === this.ContractYearDate) || !this.ContractYearDate)
            },
          },
          {
            text: 'Дата окончания',
            value: 'expiredates',
            sortable: false,
            filter: value => {
              if (this.ExpireYearDate == null && this.ExpireMonthDate == null && this.ExpireDayDate == null && !this.checkbox) return true
              if (this.checkbox) {
                return value == null
              }
              if (!value) return false
              const valSplit = value.split('.')
              return ((valSplit[0] === this.ExpireDayDate) || !this.ExpireDayDate) && ((valSplit[1] === this.ExpireMonthDate) || !this.ExpireMonthDate) && ((valSplit[2] === this.ExpireYearDate) || !this.ExpireYearDate)
            },
          },
          {
            text: 'Тип договора',
            value: 'contracttypeid',
            sortable: false,
            filter: value => {
              if (this.ContractsTypesListSelect == null) return true
              return value === parseInt(this.ContractsTypesListSelect)
            },
          },
          {
            text: 'Заказчик',
            value: 'customercagentid',
            sortable: false,
            filter: value => {
              if (this.ContractsCagentsSelect == null) return true
              return value === parseInt(this.ContractsCagentsSelect)
            },
          },
          {
            text: 'Исполнитель',
            value: 'vendorcagentid',
            sortable: false,
            filter: value => {
              if (this.ContractsVendorsSelect == null) return true
              return value === parseInt(this.ContractsVendorsSelect)
            },
          },
        ]
      },
    },
    watch: {
      ContractDate (val) {
        this.ContractDateF = this.formatDate(this.ContractDate)
      },
      ExpireDate (val) {
        this.ExpireDateF = this.formatDate(this.ExpireDate)
      },
    },
    created () {
      Promise.all(
        [
          this.$store.dispatch('getAreasList'),
          this.$store.dispatch('getCagentsList'),
          this.$store.dispatch('getContractsList', { activeonly: this.ContractsStatusSelect }),
          this.$store.dispatch('getContractsTypesList'),
        ]).then((resp) => {
        // console.log('getAreasList,getCagentsList,getContractsList,getContractsTypesList')
        this.ContractsTypesList = resp[3]
        this.ContractsAreas = resp[0]
        this.ContractsCagents = resp[1]
        this.ContractsCagents.forEach((x) => {
          x.titleinn = x.title + ' ( ИНН:' + x.inn + ' )'
        })
        this.Contracts = resp[2]
        this.tableLoading = false
        // console.log(resp)
      }).catch((error) => {
        this.tableLoading = false
        console.log('error: ' + error)
      })
    },
    methods: {
      cagentadd (newCagent) {
        this.ContractsCagents.unshift(newCagent)
        if (this.vendorAdd) {
          this.$nextTick(() => { this.newContractInfo.customercagentid = newCagent.cagentid })
        } else {
          this.$nextTick(() => { this.newContractInfo.vandorcagentid = newCagent.cagentid })
        }
      },
      addVendor (vendor) {
        this.vendorAdd = vendor
        this.showAddCagent = true
      },
      cancelAddContract () {
        this.showAddContract = !this.showAddContract
      },
      async addContract () {
        this.contractCreated = true
        if (this.$refs.form.validate()) {
          try {
            const x = await this.$store.dispatch('setNewContract', this.newContractInfo)
            if (x.ContractID > 0 && this.filesNew.length > 0) {
              const toSend = { contractid: x.ContractID }
              this.fileUploads = true
              for (var i = 0; i < this.filesNew.length; i++) {
                let filename = this.filesNew[i].name
                const fileext = filename.split('.').pop()
                filename = filename.slice(0, -(fileext.length + 1))
                toSend.filebody = this.filesNew[i]
                if (filename.length == 0) {
                  toSend.filename = fileext
                  toSend.fileextension = ''
                } else {
                  toSend.filename = filename
                  toSend.fileextension = fileext
                }
                await this.$store.dispatch('uploadContractsFiles', toSend)
              }
              const resp = await this.$store.dispatch('getContractsFileList', toSend)
              this.itemSelectfiles = resp
              this.files = []
              this.contractCreated = false
            } else {
              this.contractCreated = false
            }
            this.$store.dispatch('getContractsList', { activeonly: this.ContractsStatusSelect })
            this.$store.dispatch('setSnackbar', { state: true, text: 'Договор добавлен', color: 'success', direction: 'top center' })
            this.Contracts.push({ ...this.newContractInfo })
            this.newContractInfo = {
              ContractID: '',
              ContractTypeID: '',
              VandorCagentID: '',
              CustomerCagentID: '',
              ContractNumber: '',
              ContractDate: '',
              ExpireDate: '',
              isActive: 1,
              VendorAgentName: '',
              CustomerAgentName: '',
              VendorAgentCause: '',
              CustomerAgentCause: '',
              PayPeriod: '',
            }
            this.showAddContract = false
          } catch (e) {
            console.log(e)
            this.$store.dispatch('setSnackbar', { state: true, text: e, color: 'warning', direction: 'top center' })
            this.contractCreated = false
          }
        } else {
          this.contractCreated = false
        }
      },
      openAdd () {
        // console.log('add contract')
        this.showAddContract = true
      },
      needDot (ext) {
        if (ext && ext[0] == '.') return ''
        return '.'
      },
      fileicon (a) {
        if (this.FileExtensionImages.includes(a)) {
          a = 'img'
        }
        switch (a) {
          case 'img':
            return { icon: 'mdi-file-image', color: 'deep-orange lighten-3' }
          case 'pdf':
          case '.pdf':
            return { icon: 'mdi-file-pdf', color: 'red darken-2' }
          case 'doc':
          case 'docx':
          case '.doc':
          case '.docx':
            return { icon: 'mdi-file-word', color: 'light-blue darken-1' }
          case 'xls':
          case 'xlsx':
          case '.xls':
          case '.xlsx':
            return { icon: 'mdi-file-excel', color: 'green darken-1' }
          default:
            return { icon: 'mdi-file', color: 'blue-grey lighten-1' }
        }
      },
      download () {
        const link = document.createElement('a')
        if (this.image) {
          link.href = this.image
        } else if (this.pdf) {
          link.href = this.pdf
        } else {
          link.href = this.fileURL
        }
        link.setAttribute('download', this.imgSelect.FileName + this.needDot(this.imgSelect.FileExtension) + this.imgSelect.FileExtension)
        document.body.appendChild(link)
        link.click()
      },
      updateList (i) {
        this.Contracts = []
        this.tableLoading = true
        const toSend = { activeonly: i }
        this.$store.dispatch('getContractsList', toSend).then((resp) => {
          this.Contracts = resp
          this.tableLoading = false
        }).catch((error) => {
          this.tableLoading = false
          console.log('error: ' + error)
        })
      },
      bytesToSize (bytes) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        if (bytes === 0) return 'n/a'
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
        if (i === 0) return `${bytes} ${sizes[i]})`
        return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
      },
      changeView (id) {
        const item = this.itemSelectfiles[id]
        this.showFile({ id: id, file: item, ContractID: this.itemSelect.ContractID })
      },
      showFile (item) {
        this.fullscreen = false
        this.pdfConfig.page = 1
        this.image = null
        this.pdf = null
        this.fileURL = null
        this.imgSelect = item.file
        this.imgID = item.id
        const toSend = { fileid: item.file.FileID, contractid: item.ContractID }
        this.$store.dispatch('getContractFile', toSend).then((resp) => {
          if (this.FileExtensionImages.includes(item.file.FileExtension)) {
            this.image = window.URL.createObjectURL(new File([resp], 'img', { type: 'image/jpeg' }))
            this.imgpopup = true
          } else if (item.file.FileExtension == 'pdf') {
            this.pdf = window.URL.createObjectURL(new File([resp], 'pdf', { type: 'application/pdf' }))
            this.imgpopup = true
          } else {
            this.imgSelect.fileSize = this.bytesToSize(resp.size)
            // console.log(resp)
            this.fileURL = window.URL.createObjectURL(new File([resp], 'file', { type: 'text/html' }))
            this.imgpopup = true
          }
        })
      },
      async uploadDoc () {
        if (this.files.length != 0) {
          try {
            const toSend = { contractid: this.itemSelect.ContractID }
            this.fileUploads = true
            // console.log(this.files)
            // console.log(this.files.length)
            for (var i = 0; i < this.files.length; i++) {
              // console.log(this.files[i])
              let filename = this.files[i].name
              const fileext = filename.split('.').pop()
              filename = filename.slice(0, -(fileext.length + 1))
              toSend.filebody = this.files[i]
              if (filename.length == 0) {
                toSend.filename = fileext
                toSend.fileextension = ''
              } else {
                toSend.filename = filename
                toSend.fileextension = fileext
              }
              await this.$store.dispatch('uploadContractsFiles', toSend)
            }
            this.$store.dispatch('getContractsFileList', toSend).then((resp) => {
              this.itemSelectfiles = resp
              this.files = []
            })
            this.fileUploads = false
          } catch (err) {
            console.log(err)
            this.fileUploads = false
          }
        }
      },
      dragover () {
      },
      addDropFile (e) {
        function isValidFileType (fName, fType) {
          return extensionLists[fType].indexOf(fName) > -1
        }
        var extensionLists = {}
        extensionLists.docs = [
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/msword',
          'application/pdf',
        ]
        this.files = []
        for (var i = 0; i < e.dataTransfer.files.length; i++) {
          if (isValidFileType(e.dataTransfer.files.item(i), 'docs')) {
            this.files.push(e.dataTransfer.files.item(i))
          } else {
            this.$store.dispatch('setSnackbar', { state: true, text: 'Недопустимый формат файла(ов)' + e.dataTransfer.files.item(i), color: 'warning', direction: 'top center' })
          }
        }
        console.log(e.dataTransfer.files)
      },
      close () {
        this.addpopup = false
      },
      contractCardShow (item) {
        this.fileLoading = true
        const toSend = { contractid: item.contractid }
        this.files = []
        this.itemSelect = {}
        this.itemSelect = item
        this.addpopup = true
        this.itemSelectfiles = null
        this.$store.dispatch('getContractsFileList', toSend).then((resp) => {
          this.itemSelectfiles = resp
          this.fileLoading = false
        }).catch(() => {
          this.itemSelectfiles = null
          this.fileLoading = false
        })
      },
      clearFilter () {
        this.ContractsTypesListSelect = null
        this.ExpireDateF = null
        this.ContractDateF = null
        this.ContractsCagentsSelect = null
        this.ContractsVendorsSelect = null
        this.ContractsStatusSelect = 0
      },
      cTypeStr (type) {
        if (type != null) {
          return this.ContractsTypesList.find(x => x.contracttypeid === type).title
        } else {
          return ''
        }
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}.${month}.${year}`
      },
      zoomOut () {
        this.zoom = (this.zoom - 0.1) || 1
      },
      zoomIn () {
        this.zoom = (this.zoom + 0.1) || 2
      },
    },
  }
</script>

<style>
  .w-10 {
    width: 10%;
  }
  .w-15 {
    width: 15%;
  }
  .w-25 {
    width: 25%;
  }
  .w-35 {
    width: 35%;
  }
  #drop_area {
    width: 100%;
    height: 250px;
    background-color: red;
    text-align: center;
    padding-top: 250px;
  }
  .contr-card .v-card__text{
    font-size: 1.2rem;
  }
</style>
