<template>
  <v-dialog
    v-model="showAddCagent"
    scrollable
    max-width="540px"
    :fullscreen="fullscreen"
  >
    <v-card>
      <v-card-title>
        Добавление нового контрагента
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-row justify="center">
            <v-col cols="12">
              <v-text-field
                v-model="newCagent.inn"
                v-mask="'############'"
                label="ИНН:"
                :rules="innRules"
                required
                :counter="max"
                clearable
              >
                <template slot="append-outer">
                  <v-btn
                    v-if="!inncheckstate"
                    color="grey"
                    tile
                    small
                    class="mr-0 mb-0"
                    :disabled="innlenght"
                    @click.stop.prevent="checkINN"
                  >
                    <v-icon
                      left
                    >
                      mdi-progress-check
                    </v-icon>
                    проверить
                  </v-btn>
                  <v-icon
                    v-else-if="inncheckstate===1"
                    color="success"
                  >
                    mdi-checkbox-marked-circle-outline
                  </v-icon>
                  <v-icon
                    v-else-if="inncheckstate===2"
                    color="error"
                  >
                    mdi-close-circle-outline
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="newCagent.kpp"
                v-mask="'### ### ###'"
                label="КПП:"
                :rules="[requireVal, min9]"
                clearable
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="newCagent.opf"
                :items="legalFormsList"
                item-text="title"
                item-value="legalformid"
                label="ОПФ:"
                outlined
                :rules="[requireVal]"
                clearable
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="newCagent.name"
                label="Юридическое название:"
                :rules="[requireVal]"
                clearable
              />
            </v-col>
            <v-col cols="8">
              <v-select
                v-model="newCagent.sn"
                :items="taxSystemsList"
                item-text="title"
                item-value="taxation_system_id"
                label="Система налогооблажения:"
                outlined
                :rules="[requireVal]"
                clearable
                @change="changeTaxSys"
              />
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="newCagent.snp"
                :items="taxRatesList"
                item-text="percent"
                item-value="taxrateid"
                label="%"
                outlined
                :rules="[requireVal]"
                clearable
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="newCagent.bank"
                :items="banksList"
                item-text="titlebik"
                item-value="bankid"
                label="Банк:"
                outlined
                :rules="[requireVal]"
                required
                clearable
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="newCagent.pacc"
                v-mask="'##### ##### ##### #####'"
                label="Номер р/счета:"
                :rules="[requireVal, min20]"
                clearable
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="cancelAddContract"
        >
          отмена
        </v-btn>
        <v-spacer />
        <v-btn
          color="green darken-1"
          text
          :loading="cagentadding"
          @click="addCagent"
        >
          добавить
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'AddCagents',
    data () {
      return {
        max: 12,
        requireVal: v => !!v || 'Необходимо заполнить',
        innRules: [
          v => !!v || 'Необходимо заполнить',
          v => (!!v && v.length > 9) || 'ИНН не может быть меньше 10 цифр',
          v => this.checkDigitINN(v) || 'Неправильное контрольное число',
          () => !this.inncheckMessage || 'С этим ИНН добавлена организация:' + this.inncheckMessage,
        ],
        min9: v => (!!v && v.length > 10) || 'КПП должен быть 9 цифр',
        min20: v => (!!v && v.length > 22) || 'Номер р/счета должен быть 20 цифр',
        inncheckMessage: null,
        inncheckstate: null,
        valid: false,
        fullscreen: false,
        banksList: [],
        taxSystemsList: [],
        legalFormsList: [],
        taxRatesList: [],
        cagentadding: false,
        newCagent: {
          inn: null,
          name: null,
          bank: null,
          opf: null,
          sn: null,
          snp: null,
          kpp: null,
          pAcc: null,
        },
      }
    },
    computed: {
      innlenght () {
        return !(this.newCagent.inn && this.newCagent.inn.length > 9)
      },
      showAddCagent: {
        get () {
          return this.$store.getters.showAddCagent
        },
        set (val) {
          this.$store.commit('showAddCagent', val)
        },
      },
    },
    watch: {
      'newCagent.inn' (newVal, oldVal) {
        if (oldVal && oldVal.length > 9 && (newVal == null || newVal.length < 10)) {
          this.inncheckstate = null
          this.inncheckMessage = null
        }
      },
      showAddCagent (newVal) {
        if (newVal) {
          this.newCagent = {
            inn: null,
            name: null,
            bank: null,
            opf: null,
            sn: null,
            snp: null,
            kpp: null,
            pacc: null,
          }
          if (this.$refs.form) this.$refs.form.reset()
        }
      },
    },
    mounted () {
      this.$store.dispatch('BanksList').then((v) => {
        this.banksList = v
        this.banksList.forEach((x) => {
          x.titlebik = x.name + ' ( БИК:' + x.bik + ' )'
        })
      })
      this.$store.dispatch('LegalFormsList').then((v) => {
        this.legalFormsList = v
      })
      this.$store.dispatch('TaxSystemsList').then((v) => {
        this.taxSystemsList = v
      })
      this.$store.dispatch('TaxRatesList').then((v) => {
        v.forEach((val) => {
          if (!val.percent) {
            val.percent = '-'
          }
        })
        this.taxRatesList = v
      })
    },
    methods: {
      checkDigit (inn, coefficients) {
        let n = 0
        for (const i in coefficients) {
          n += coefficients[i] * inn[i]
        }
        return parseInt(n % 11 % 10)
      },
      checkDigitINN (inn) {
        let result = false
        if (inn) {
          switch (inn.length) {
            case 10:
              var n10 = this.checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8])
              if (n10 === parseInt(inn[9])) {
                result = true
              }
              break
            case 12:
              var n11 = this.checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
              var n12 = this.checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
              if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
                result = true
              }
              break
          }
        }
        return result
      },
      checkINN () {
        this.inncheckMessage = null
        console.log('checkINN', this.newCagent.inn)
        if (!this.checkDigitINN(this.newCagent.inn)) {
          this.inncheckstate = 2
        } else {
          this.$store.dispatch('checkINN', this.newCagent.inn).then((v) => {
            if (v.title) {
              this.inncheckstate = 2
              this.inncheckMessage = v.title
              this.$refs.form.validate()
            } else {
              this.inncheckstate = 1
            }
          })
        }
      },
      changeTaxSys (newVal) {
        if (newVal) {
          const index = this.taxSystemsList.findIndex(x => x.taxation_system_id === newVal)
          this.newCagent.snp = this.taxSystemsList[index].default_taxrateid
        }
      },
      async addCagent () {
        this.cagentadding = true
        if (this.inncheckstate !== 1 && this.newCagent.inn && this.newCagent.inn.length > 9) {
          await this.checkINN()
        }
        console.log(this.$refs.form.validate())
        if (this.$refs.form.validate() && this.valid) {
          const kpp = this.newCagent.kpp.replace(/\s+/g, '')
          const pacc = this.newCagent.pacc.replace(/\s+/g, '')
          // console.log('field checked2', pAcc)
          // console.log('field checked3', this.newCagent.kpp)
          // console.log('field checked4', this.newCagent.pAcc)
          // [Title, LegalFormID, inn, payaccount, BankID, kpp, TaxSystemID, TaxRateID]
          const cagent = {
            title: this.newCagent.name,
            legalformid: this.newCagent.opf,
            inn: this.newCagent.inn,
            bankid: this.newCagent.bank,
            kpp: kpp,
            payaccount: pacc,
            taxsystemid: this.newCagent.sn,
            taxrateid: this.newCagent.snp,
          }
          try {
            const id = await this.$store.dispatch('CagentsCagentAdd', cagent)
            const presentationtitle = cagent.title
            const newCagent = {
              cagentid: id,
              title: cagent.title,
              inn: cagent.inn,
              presentationtitle: presentationtitle,
              taxationsystemid: cagent.taxsystemid,
              taxrateid: cagent.taxrateid,
              titleinn: cagent.title + '( ИНН:' + cagent.inn + ' )',
            }
            this.$emit('cagentadd', newCagent)
            this.$store.dispatch('setSnackbar', { state: true, text: 'Контрагент добавлен', color: 'success', direction: 'top center' })
            this.cagentadding = false
            this.showAddCagent = false
          } catch (e) {
            this.$store.dispatch('setSnackbar', { state: true, text: e, color: 'warning', direction: 'top center' })
          }
        }
        this.cagentadding = false
      },
      cancelAddContract () {
        this.showAddCagent = false
      },
    },
  }
</script>

<style>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
</style>
